<template>
  <v-dialog v-model="dialog" width="700">
    <template v-slot:activator="{ on, attrs }">
      <v-icon size="25" color="info" v-bind="attrs" v-on="on">mdi-information</v-icon>
    </template>

    <v-card>
      <v-card-title class="text-h5">
        <v-row class="align-center">
          <v-col> {{ sessionData.course_code }} - {{ sessionData.clients }} </v-col>
          <v-col cols="auto">
            <v-btn fab small depressed @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text>
        <v-row no-gutters>
          <v-col cols="12" sm="6">
            <v-row no-gutters>
              <v-col cols="12">
                <span :class="titleClasses">Cliente:</span>
                <span :class="textClasses">{{ sessionData.clients }}</span>
              </v-col>

              <v-col cols="12">
                <span :class="titleClasses">Centro:</span>
                <span :class="textClasses">{{ sessionData.centers }}</span>
              </v-col>

              <v-col cols="12">
                <span :class="titleClasses">Formador:</span>
                <span :class="textClasses">{{ sessionData.trainer_name }}</span>
              </v-col>
              <v-col cols="12">
                <span :class="titleClasses">Alumnos:</span>
                <span :class="textClasses">{{ sessionData.assistances_count }}</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" sm="6">
            <v-row no-gutters>
              <v-col cols="12">
                <span :class="titleClasses">Tipo:</span>
                <span :class="textClasses">{{ sessionData.course_category }}</span>
              </v-col>

              <v-col cols="12">
                <span :class="titleClasses">Lugar:</span>
                <span :class="textClasses">
                  {{ sessionData.course_address }}, {{ sessionData.course_city }}
                </span>
              </v-col>

              <v-col cols="12">
                <span :class="titleClasses">Fecha y hora:</span>
                <span :class="textClasses">{{ sessionData.date }}</span>
              </v-col>

              <v-col cols="12">
                <span :class="titleClasses">Sesión:</span>
                <span :class="textClasses">{{ sessionData.session_number }}</span>
              </v-col>

              <v-col cols="12">
                <span :class="titleClasses">Interlocutor:</span>
                <span :class="textClasses">{{ sessionData.interlocutor }}</span>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="12" class="mt-2">
            <span :class="titleClasses">Comentarios</span>
            <p :class="textClasses">{{ sessionData.course_comments || "Sin comentarios" }}</p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :to="{ name: 'trainerSession', params: { id: sessionData.id } }"
          color="primary"
          depressed
        >
          Abrir sesión <v-icon class="ml-2">mdi-arrow-right-thick</v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    sessionData: { type: Object, required: true },
  },
  data: () => ({
    dialog: null,
    titleClasses: "text-subtitle-2 font-weight-bold mr-2",
    textClasses: "text-body-2",
  }),
};
</script>

<style scoped></style>
